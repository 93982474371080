<template>
<section class="performances">
    <div class="performances-header vacation-header container">
        <div class="vacation-header-left vacation-header-left_desc">
            <router-link to="/" class="main-nav-link">
                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="14" viewBox="0 0 8 14" fill="none">
                    <path d="M7 13L1.70711 7.70711C1.31658 7.31658 1.31658 6.68342 1.70711 6.29289L7 1" stroke="#373737"
                        stroke-linecap="round" />
                </svg>
                Главная
            </router-link>
            <h1 class="vacation-header-left__title">Лисий Шаг</h1>
            <p class="vacation-header-left__info">Перенесемся в США времен Сухого закона и разгара Великой депрессии. Бедняки ищут работу и выпивку, предприимчивые — прибыль и выгоду, талантливые мечтают обрести славу. Точкой притяжения и тех, и других становятся Танцевальные марафоны, так называемые «танцы до упаду». Бездомные и безработные, безумные и безжалостные будут бороться за денежный приз… Танцуем фокстрот, дамы и господа?</p>
            <div class="vacation-header-left-row">
                <a class="vacation-header-left__link" href="https://widget.afisha.yandex.ru/w/events/589610?clientKey=257d94f6-aa53-483d-bdda-6b53d1bcdf28&regionId=43">
                    Купить билет
                    <svg xmlns="http://www.w3.org/2000/svg" width="31" height="12" viewBox="0 0 31 12" fill="none">
                        <path d="M25 11L30 6L25 1" stroke="#CB181A" stroke-linecap="round"/>
                        <path d="M30 6H1" stroke="#CB181A" stroke-linecap="round"/>
                    </svg>
                </a>
            </div>
        </div>
        <div class="vacation-header-left vacation-header-left_mobile container">
            <router-link to="/" class="main-nav-link">
                <svg xmlns="http://www.w3.org/2000/svg" width="8" height="14" viewBox="0 0 8 14" fill="none">
                    <path d="M7 13L1.70711 7.70711C1.31658 7.31658 1.31658 6.68342 1.70711 6.29289L7 1" stroke="#373737"
                        stroke-linecap="round" />
                </svg>
                Главная
            </router-link>
            <h1 class="vacation-header-left__title">Лисий Шаг</h1>
            <p class="vacation-header-left__info">Перенесемся в США времен Сухого закона и разгара Великой депрессии. Бедняки ищут работу и выпивку, предприимчивые — прибыль и выгоду, талантливые мечтают обрести славу. Точкой притяжения и тех, и других становятся Танцевальные марафоны, так называемые «танцы до упаду». Бездомные и безработные, безумные и безжалостные будут бороться за денежный приз… Танцуем фокстрот, дамы и господа?</p>
            <div class="vacation-header-left-row">
                <a class="vacation-header-left__link" href="https://afisha.yandex.ru/kazan/theatre/lisii-shag?city=kazan&source=suggest">
                    Купить билет
                    <svg xmlns="http://www.w3.org/2000/svg" width="31" height="12" viewBox="0 0 31 12" fill="none">
                        <path d="M25 11L30 6L25 1" stroke="#CB181A" stroke-linecap="round"/>
                        <path d="M30 6H1" stroke="#CB181A" stroke-linecap="round"/>
                    </svg>
                </a>
            </div>
        </div>
        <div class="vacation-header-right">
            <div class="vacation-header-right__img" :style="{ backgroundImage: 'url(' + require('@/assets/img/performances/fox_step_2.jpg') + ')' }"></div>
        </div>
    </div>
    <div class="performances-body">
        <div class="container">
            <div class="performances-body-desc">
                <p>ReLab Family – команда, подарившая Казани множество разноформатных гастрономических проектов и коктейльных баров, запускает новый Иммерсивный Спектакль «Лисий Шаг». </p>
                <p>Камерная и атмосферная локация, в которую мечтали попасть многие: speak-easy бар Mr.Willard. Наконец, визит к таинственному Орзамусу станет возможным. </p>
            </div>
            <div class="performances-body-section">
                <h5 class="performances-body-section__title">Уникальный актерский состав:</h5>
                <p>&bull; <span>Павел Густов</span> – артист Казанского ТЮЗа,  Заслуженный артист РТ</p>
                <p>&bull; <span>Ярослав Кац</span> – артист Казанского  ТЮЗа</p>
                <p>&bull; <span>Мария Мухортова</span> – артистка Казанского ТЮЗа</p>
                <p>&bull; <span>Александр Галимов</span> – артист, актёр театральной резиденции "Особняка Демидова" </p>
                <p>&bull; <span>Илья Санников</span> – артист, актёр театральной резиденции "Особняк Демидова", "Творческой лаборатории "Угол"</p>
                <p>&bull; <span>Елена Панова</span> – артистка, актриса театральной  резиденции "Особняк Демидова", "Moñ"</p>
                <p>&bull; <span>Иван Балашов</span> – актёр театральной  резиденции "Особняк  Демидова", "Moñ", Творческой лаборатории "Угол"</p>
                <p>&bull; <span>Роберт Валиев</span> – артист, актёр театральной резиденции "Особняк Демидова"</p>
                <p>&bull; <span>Яна Арсланбекова</span> – студентка 4 русского драматического курса Казанского театрального училища, актриса  Творческой лаборатории "Угол"</p>
                <p>&bull; <span>Евфросиния Шишова</span> – студентка 3 русского драматического курса Казанского театрального училища</p>
            </div>
            <div class="performances-body-section">
                <h5 class="performances-body-section__title">Режиссер спектакля:</h5>
                <p><span>Юлия Голованова</span> – основатель и режиссёр независимого экспериментального театра «ТеатрForm» (Тольятти), актриса театральной резиденции «Особняк Демидова», режиссёр театральной резиденции «Особняк Демидова» и творческой лаборатории «Угол».</p>
            </div>
            <div class="performances-body-section">
                <p><i>Мы не делим актёров на первый и второй состав. Вам в любом случае повезет наслаждаться захватывающей игрой и участвовать в ней.</i></p>
            </div>
            <div class="performances-body-section">
                <p>&bull; Спектакль начинается ровно в <span>19:30</span>. Мы уважаем актеров и зрителей, и после указанного времени не впускаем в бар. Просим учесть этот момент при планирование маршрута.</p>
                <p>&bull; В стоимость билета включены денежные банкноты. Вы можете потратить их на напитки или участие в развитии событий. </p>
                <p>&bull; Вход для гостей открыт с <span>18:30</span>. Время для тех, кто проголодался и/или хочет заказать напитки сверх тех, что уже включены в стоимость билета. </p>
                <p>&bull; Коктейли содержат алкоголь. Если Вы не употребляете спиртные напитки, сообщите нам об этом на входе.</p>
                <p>&bull; Заказы по меню кухни принимаются до <span>19:00</span>, на напитки — до <span>19:20</span>. Во время спектакля заказы не принимаются. К сожалению, это невозможно осуществить, не помешав игре актеров. Поэтому предлагаем Вам прийти заблаговременно до спектакля, либо остаться в баре после него.</p>
                <p>&bull; Во время спектакля в баре действует свободная рассадка. Соответственно, пришедшие раньше – могут занимать понравившиеся места. Те, кто придет ближе к началу – оставшиеся свободными. Если Вам нужно занять конкретное место по состоянию здоровья – мы урегулируем этот вопрос.</p>
                <p>&bull; Фото и видеосъемка категорически разрешены. Как и упоминание аккаунта бара.</p>
                <p>&bull; Если Вы пожелаете вручить цветы актерскому составу — мы с радостью примем их на время спектакля и позаботимся о сохранности.</p>
                <p>&bull; Дверь на площадку открывается по звонку. В дни спектаклей действует дополнительный номер: <a href="tel:+79172765938" style="color:#CB181A">+7 (917) 276-59-38.</a></p>
            </div>
            <div class="performances-body-section">
                <p><i>До встречи на спектакле!</i></p>
            </div>
            <div class="performances-body-section contacts">
                <iframe src="https://yandex.ru/map-widget/v1/?um=constructor%3Acffb52b15c3bd5119702bbaa1e3095c55cc2c15ba286e5d5131d3cce27fd00ad&amp;source=constructor" width="100%" height="400" frameborder="0"></iframe>
            </div>
        </div>
    </div>
</section>
</template>